var GO_Extension_Forms = {
    init: function() {
        GO_Extension_Forms.upload.init();
        GO_Extension_Forms.send.init();
    },
    upload: {
        init: function() {

            $('.go-form__file').each(function(){
                var $t = $(this),
                    $form = $t.closest('form'),
                    $input = $t.find('input[type="file"]'),
                    $inputHidden = $t.find('input[type="text"]'),
                    $progress = $t.find('.go-form__file__progress'),
                    $messages = $t.find('.go-form__file__messages');
                                
                $input.fileupload({
                    url: 'includes/ajax.php',
                    dataType: 'json',
                    formData: {
                        action : 'upload',
                        type : $form.data('form-type')
                    },
                    maxFileSize: (40 * 1024 * 1024),
                    acceptFileTypes: /\.(jpe?g|tiff?)$/i,
                    done: function (e, data) {
                        $messages.html('');
                        var $message_container = $('<div></div>');
                        try {
                            var file = data.result.file[0];
                            console.log(typeof(file.error));
                            if(typeof(file.error) !== 'undefined') {
                                $message_container.addClass('go-form__file__messages__error').html(file.error);
                            } else {
                                //$input.val(file.name);
                                $inputHidden.val(file.url);
                                $message_container.addClass('go-form__file__messages__success').html('Die Datei wurde erfolgreich hochgeladen!');
                            }
                            
                        } catch(e) {
                            console.log(e);
                            $message_container.addClass('go-form__file__messages__error').html('Ein unbekannter Fehler!');
                        }
                        
                        $messages.append($message_container);
                    },
                    finished: function(e, data) {
                        console.log('failed', data);
                    },
                    progressall: function(e, data) {
                        var progress = parseInt(data.loaded / data.total * 100, 10);
                        $progress.find('div').css(
                            'width',
                            progress + '%'
                        );                    
                    },
                    disableImagePreview: true,
                    previewThumbnail: false
                });                
                
            });
            $(document).ajaxComplete(function(e, xhr, settings) {
                console.log('ajaxComplete', settings);
                setTimeout(function() {
                    try {
                        if(formData.action == '"upload"') {

                        }
                    } catch(e) {}
                }, 1000);
            });
        }
    },
    send: {
        init: function() {
            $('.go-form').submit(function(e) {
                var $form = $(this),
                    data = $form.serializeArray(),
                    $form_feedback = $('.go-form__feedback').html('');
                e.preventDefault();
                console.log(data);
                data.push({
                    name : 'action',
                    value : 'send_photo_competition'
                });
                
                data.push({
                    name : 'type',
                    value : $form.data('form-type')
                });                
                
                $('[name]').removeClass('invalid');
                
                $.ajax({
                    data: data,
                    url: 'includes/ajax.php',
                    dataType: 'json',
                    method: "POST",
                }).always(function(data) {
                    var $message = $('<div></div>');
                    try {
                        console.log(data);
                        if(data.success) {
                            var has_parameters = window.location.href.match(/\?/);
                            window.location.href = window.location.href + (has_parameters?'&':'?')+'submitted';
                            console.log('go to' + window.location.href + (has_parameters?'&':'?')+'submitted');
                        } else {
                            $message.addClass('go-form__feedback__error');
                            $.each(data.fields, function(i, field) {
                                $('[name="'+field+'"]').addClass('invalid');
                            });
                            $message.html('Einige Felder wurden nicht korrekt ausgefüllt!')
                        }
                    } catch(e) {
                        $message.addClass('go-form__feedback__error').html('Ein unbekannter Fehler ist aufgetreten!');
                    }
                    
                    $form_feedback.append($message)
                    
                    console.log(data);
                });
            });
        }
    }
}

GO_Extension_Forms.init();